import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Sidebar from '../../components/sidebar'
import Img from "gatsby-image"

 
const BasSurfaceFichePage = ({data}) => {
    return(
        <Layout>
            <SEO title="certificats de qualité Bas Surface" />
            <div class="grid grid-cols-1 md:grid-cols-4 mt-8 gap-8 md:mx-40 md:my-5 mx-3"> 
            <div class="grid grid-cols-1 md:col-span-3 mx-3"> {/** first element */}
                <h1 class="text-2xl font-bold mb-2 md:mt-3">Fiche Technique</h1>
                <div class="border-solid border-2 border-red-500 w-1/12 mb-6"></div>
                    { data.allFile.edges.map(({node}) =>
                    <div class="fiche mb-2">
                        <Img class="fiche-" fluid={node.childImageSharp.full} />
                    </div>
                    )}
            </div>
            <div>
                <Sidebar />
            </div>
            </div>
        </Layout>
) 
}
export const query = graphql`
query FicheImages {
  allFile (filter:{relativeDirectory: {eq: "img/telechargement/bas-surface/fiche-technique"}}){
    edges {
      node {
        childImageSharp {
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`
export default BasSurfaceFichePage